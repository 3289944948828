import MDRouter from "@/service/router";
import Error from "@@/common/Error.vue";
import lowerdev from "@/service/lowerdev";
import domain from "@/service/domain";
import { mapState } from "vuex";
// eslint-disable-next-line no-unused-vars
import { env } from "md-base-tools/env";
import {
// eslint-disable-next-line no-unused-vars
getSearchAll, getMdsearchComprehensive, getSearchKeywordsBanner, getSearchKeyWords, getProject_zcRrank_list, getCategoryDetails, getHotList, dropdownList, follow } from "@api/index";
import { mdToast } from "../../utils/tools";
export default {
  components: {
    Error
  },
  computed: {
    ...mapState({
      linkUrl: state => state.user.linkUrl,
      userIcon: state => state.user.userIcon,
      userId: state => state.user.userId,
      platform: state => state.user.platform
    }),
    ifApp() {
      return env.isWxApp() || env.isModianIos() || env.isModianAndroid();
    }
  },
  data() {
    return {
      width: document.body.clientWidth * 0.88,
      typeMap: ["pro", "mall_pro", "kuji", "luckycards", "idea"],
      typeMapStr: {
        kuji: "幸运商店",
        luckycards: "抽卡"
      },
      dialogVisible: false,
      searchShow: false,
      key: "",
      page: 1,
      dorp: false,
      swiperIndex: 0,
      list: [],
      ads: [],
      guessList: [],
      correction_words: {
        err_words: "",
        want_words: ""
      },
      guessText: '',
      historyList: [],
      huoList: [],
      projectList: [],
      productList: [],
      dropdownList: [],
      request_id: "",
      requestId: "",
      hotClick: false,
      search_type: "all"
    };
  },
  watch: {
    historyList: {
      handler(newValue) {
        localStorage.setItem("historyList", JSON.stringify(newValue));
      },
      deep: true
    }
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    userHeader.getMdlink({
      type: "ucenter"
    });
    this.historyList = JSON.parse(localStorage.getItem("historyList")) || [];
    lowerdev.addbind(document.getElementsByClassName("content")[0], "scroll", this.reloadScroll);
    this.getSearchKeyWords();
    this.getProject_zcRrank_list();
    this.getCategoryDetails();
    this.getHotList();
  },
  beforeUnmount() {
    lowerdev.removebind(document.getElementsByClassName("content")[0], "scroll", this.reloadScroll);
  },
  methods: {
    deleteInput() {
      this.dorp = false;
      this.key = '';
      this.$refs.keyinput.blur();
    },
    dorpCli(text) {
      this.dorp = false;
      this.key = text;
      if (!this.historyList.includes(this.key)) {
        this.historyList.unshift(this.key);
      }
      this.sensors.track("SearchBtnClick", {
        key_word: this.key,
        search_type: "联想"
      });
      this.getSearchAll(1);
      this.getSearchKeywordsBanner();
    },
    tipClick(want_words) {
      this.key = want_words;
      this.sensors.track("SearchBtnClick", {
        key_word: this.key,
        search_type: "推荐"
      });
      if (!this.historyList.includes(this.key)) {
        this.historyList.unshift(this.key);
      }
      this.getSearchAll(1);
      this.getSearchKeywordsBanner();
    },
    async ketInput() {
      if (this.key !== "") {
        this.dorp = true;
        const {
          data
        } = await dropdownList({
          content: this.key
        });
        this.dropdownList = data.list;
      } else {
        this.dorp = false;
      }
    },
    toProduct(id, idx) {
      this.sensors.track("PositionClick", {
        page_source: "搜索结果页",
        spu_id: id,
        module: ` 搜索页${idx}`
      });
      MDRouter.goMallInfo(id);
    },
    guessClick(item, idx) {
      this.sensors.track("PositionClick", {
        key_word: item.text,
        page_source: "搜索页",
        module: `推荐关键词${idx}`
      });
      location.href = item.url;
    },
    hotClickFn(item) {
      this.hotClick = true;
      this.key = item.text;
      this.sensors.track("SearchBtnClick", {
        key_word: this.key,
        search_type: "热搜"
      });
      if (!this.historyList.includes(this.key)) {
        this.historyList.unshift(this.key);
      }
      this.getSearchAll(1);
      this.getSearchKeywordsBanner();
    },
    async getHotList() {
      const {
        data
      } = await getHotList();
      this.huoList = data.list;
    },
    async getProject_zcRrank_list() {
      const {
        data
      } = await getProject_zcRrank_list({
        category: 1,
        page: 1,
        page_size: 10,
        type: 10000
      });
      this.projectList = data.list;
    },
    async getCategoryDetails() {
      const {
        data
      } = await getCategoryDetails({
        category: "rank",
        rank: "day",
        page: 1,
        page_size: 10
      });
      this.productList = data.list;
      this.requestId = data.request_id;
    },
    async getSearchKeyWords() {
      const {
        data
      } = await getSearchKeyWords();
      if (data && data.ads) {
        this.guessList = data.ads;
        this.guessText = data.ads[0].text;
      }
    },
    onChange(index) {
      this.swiperIndex = index;
    },
    back() {
      if (this.searchShow) {
        this.key = "";
        this.hotClick = false;
        this.searchShow = false;
        this.dorp = false;
      } else {
        history.back();
      }
    },
    text_replace(str, key) {
      if (key) {
        let newStr = "";
        for (let a of str) {
          if (key.includes(a)) {
            newStr += `<em>${a}</em>`;
          } else {
            newStr += a;
          }
        }
        return newStr;
      } else {
        return str;
      }
    },
    encodeUnicode(str) {
      var res = [];
      for (var i = 0; i < str.length; i++) {
        res[i] = ("00" + str.charCodeAt(i).toString(16)).slice(-4);
      }
      return "\\u" + res.join("\\u");
    },
    jumpRank() {
      location.href = domain.wap + "/rank";
    },
    jumpShop(item) {
      location.href = domain.wap + "/product/pop/" + item.card_info.mall_shop_info.shop_id + ".html";
    },
    jumpProject(item) {
      MDRouter.goProInfo(item.card_info.product_info.id);
    },
    jumpLi(item, idx) {
      setTimeout(() => {
        this.sensors.track("SearchResultClick", {
          result_type: item.type,
          key_word: this.key,
          position: idx,
          item_id: item.card_info.id,
          spu_id: item.card_info.product_id,
          activity_id: item.card_info.product_id,
          card_id: item.card_info.extract_card_id,
          item_name: item.card_info.name,
          product_name: item.card_info.name,
          activity_name: item.card_info.name,
          card_name: item.card_info.name
        });
      }, 100);
      const arrMap = ["all", "mall_pro", "pro"];
      if (arrMap.includes(this.search_type)) {
        this.sensors.track("PositionClick", {
          page_source: "搜索结果页",
          module: ` 搜索页${idx}`,
          item_id: item.card_info.id,
          spu_id: item.card_info.product_id,
          activity_id: item.card_info.product_id,
          card_id: item.card_info.extract_card_id,
          item_name: item.card_info.name,
          product_name: item.card_info.name,
          activity_name: item.card_info.name,
          card_name: item.card_info.name
        });
      }
      setTimeout(() => {
        switch (item.type) {
          case "pro":
            MDRouter.goProInfo(item.card_info.id);
            break;
          case "idea":
            MDRouter.goIdeaInfo(item.card_info.id);
            break;
          case "mall_pro":
            MDRouter.goMallInfo(item.card_info.product_id);
            break;
          case "kuji":
            location.href = item.card_info.url;
            break;
          case "luckycards":
            MDRouter.goLuckycardsInfo(item.card_info.extract_card_id);
            break;
          case "shop":
            location.href = domain.wap + "/product/pop/" + item.card_info.shop_id + ".html";
            break;
          case "merchant":
            location.href = domain.wap + "/user/homePage/" + item.card_info.user_id;
            break;
        }
      }, 100);
    },
    async like(item, userid) {
      const {
        status
      } = await follow({
        to_user_id: userid,
        type: 2
      });
      if (status == 0) {
        item.card_info.is_follow = true;
        mdToast("关注成功");
      }
      // }
    },
    jumpLiB(item, type, idx) {
      this.sensors.track("PositionClick", {
        page_source: "搜索页",
        module: `${type === "pro" ? "搜索热搜排行" : "搜索商品热销"}${idx}`,
        item_id: item.id,
        spu_id: item.product_id,
        activity_id: item.product_id,
        card_id: item.extract_card_id,
        item_name: item.name,
        product_name: item.name,
        activity_name: item.name,
        card_name: item.name
      });
      switch (type) {
        case "pro":
          MDRouter.goProInfo(item.id);
          break;
        case "mall_pro":
          MDRouter.goMallInfo(item.product_id);
          break;
      }
    },
    jumpSrc(src) {
      location.href = src;
    },
    jumpCp() {},
    toggleNavigation(type) {
      this.search_type = type;
      this.getSearchAll(1);
    },
    keyBtn() {
      this.key = this.key ? this.key : this.guessText;
      this.sensors.track("SearchBtnClick", {
        key_word: this.key,
        search_type: "输入框"
      });
      if (!this.historyList.includes(this.key)) {
        this.historyList.unshift(this.key);
      }
      this.dorp = false;
      this.getSearchAll(1);
      this.getSearchKeywordsBanner();
    },
    historyClick(key) {
      this.key = key;
      this.sensors.track("SearchBtnClick", {
        key_word: this.key,
        search_type: "搜索历史"
      });
      this.getSearchAll(1);
      this.getSearchKeywordsBanner();
    },
    async getSearchKeywordsBanner() {
      const {
        data
      } = await getSearchKeywordsBanner({
        key_word: this.key
      });
      if (data && data.ads) {
        this.ads = data.ads;
      } else {
        this.ads = [];
      }
    },
    async getSearchAll(num) {
      if (num === 1) {
        this.list = [];
        this.request_id = "";
      }
      this.page = num;
      const {
        data
      } = await getMdsearchComprehensive({
        from_request_id: this.hotClick ? this.requestId : "",
        content: this.key,
        page: this.page,
        page_size: 20,
        request_id: this.request_id,
        build: 0,
        search_type: this.search_type
      });
      this.correction_words = data.correction_words;
      this.searchShow = true;
      this.request_id = data.request_id;
      this.loading = !data.is_next;
      this.list.push(...data.list);
      if (this.list.length) {
        if (this.list[0].card_info.link) {
          this.key = "";
          this.hotClick = false;
          this.searchShow = false;
          this.dorp = false;
          this.jumpSrc(this.list[0].card_info.link);
        }
      }
    },
    reloadScroll(e) {
      let dom = e.target;
      let h = dom.offsetHeight;
      let h_scroll = dom.scrollHeight;
      let t_scroll = dom.scrollTop;
      if (this.loading || !this.searchShow) {
        return false;
      }
      if (h_scroll < h + t_scroll + 100) {
        this.loading = true;
        this.getSearchAll(++this.page);
      }
    }
  }
};